import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";

const ResearchBanner = () => {
  const query = graphql`
    {
      allContentfulResearch(
        sort: { fields: [createdAt], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            id
            slug
            title
            subtitle {
              subtitle
            }
            shortSummary {
              shortSummary
            }
            summaryImage {
              id
              file {
                url
              }
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={data => {
        const cards = data.allContentfulResearch.edges.map(e => (
          <Link to={`/research/${e.node.slug}`}>
            <div className="researchBannerCard">
              <img src={e.node.summaryImage.file.url} />
              <div className="bannerCardHeader">{e.node.title}</div>
              <div className="bannerText">
                {e.node.shortSummary.shortSummary}
              </div>
            </div>
          </Link>
        ));
        return (
          <div className="newBanner">
            <div className="bannerHeader">In-Depth Research</div>
            <div className="bannerSubHeader">
              Thought Leadership with a Differentiated Point of View
            </div>
            <div className="bannerBody">{cards}</div>
            <Link to="/research#researchContent">
              <div className="buttonContainer">
                <div className="button">Read All Our Research</div>
              </div>
            </Link>
          </div>
        );
      }}
    />
  );
};

export default ResearchBanner;

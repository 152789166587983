import React from "react";

import Home from "../components/Home";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ogImage from "../images/OG_Image.png";

const IndexPage = () => (
  <Layout>
    <SEO
      title=""
      keywords={[
        "Flowspring",
        "competitive intelligence",
        "asset managers",
        "flow forecasting",
        "fee optimization",
        "ETFs",
        "mutual funds",
      ]}
      ogImage={ogImage}
    />
    <Home />
  </Layout>
);

export default IndexPage;

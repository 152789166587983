import React from "react";
import { Link } from "gatsby";
import CountUp from "react-countup";
import factorImportance from "../images/FactorImportance.png";
import ft from "../images/Financial_Times_corporate_logo.svg";
import flowvectors from "../images/FlowVectors.png";
import forecast from "../images/Forecast.png";
import nyt from "../images/NewYorkTimes.svg";
import boxes from "../images/boxes.png";
import flowHeadwinds from "../images/flowHeadwinds.png";
import ignites from "../images/ignites.png";
import citywire from "../images/logo-citywire.svg";
import HeroVid from "../vid/heroVid.mp4";
import HeroVidWebm from "../vid/heroVid.webm";
import ResearchBanner from "./ResearchBanner";

const Home = () => (
  <>
    <div className="highlight" style={{ position: "relative" }}>
      <img
        id="boxes"
        src={boxes}
        style={{ width: "75%", position: "absolute", bottom: 0, right: 0 }}
      />
      <div className="hero-container">
        <div className="hero-textholder">
          <div className="hero-title">
            {"More "}
            <span className="accentColor">Insight</span>
            {" in Less Time"}
          </div>
          <div className="hero-subtitle">
            Unparalleled Competitive Intelligence for Asset Managers
            <div className="hero-descriptor">
              Accelerate AUM growth with our cutting-edge competitive
              intelligence. Whether you&apos;re launching new products, enhancing
              your marketing strategy, optimizing product pricing, or
              developing your distribution tactics, Flowspring has you
              covered. Our actionable analytics are designed to support your
              toughest decisions.
            </div>
          </div>

          <a href="https://app.flowspring.com/accounts/signup">
            <div className=" hero-button">FREE TRIAL</div>
          </a>
        </div>

        <video className="hero-video" loop muted playsInline autoPlay>
          <source src={HeroVidWebm} type="video/webm" />
          <source src={HeroVid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <div className="newBanner">
      <div id="product" className="bannerHeader">What can you do with Flowspring?</div>
      <div className="bannerSubHeader">
        Support Your Key Decisions with Data-Driven Insight
      </div>
      <div className="bannerBody">
        <div className="bannerCard">
          <Link to="/competitive_intelligence">
            <img src={flowvectors} />
            <div className="bannerCardHeader">Competitive Intelligence</div>
            <div className="bannerText">
              {`Discover which competitors are winning and losing flows from your funds. Then dig deeper to 
                                understand the key factors driving those flows.`}
            </div>
          </Link>
        </div>

        <div className="bannerCard">
          <Link to="/flow_forecasting">
            <img src={forecast} />
            <div className="bannerCardHeader">
              Flow Forecasting & Attribution
            </div>
            <div className="bannerText">
              {`Use Flowspring's flow and fee forecasts to identify opportunities for growth or make forward-looking
                            decisions about production rationalization.`}
            </div>
          </Link>
        </div>
        <div className="bannerCard">
          <Link to="/fee_optimization">
            <img src={flowHeadwinds} />
            <div className="bannerCardHeader">Fee Optimization</div>
            <div className="bannerText">
              {`Quickly identify shareclasses that are over- or under-priced, then quantify how heavily that will impact
                            your future organic growth.`}
            </div>
          </Link>
        </div>
        <div className="bannerCard">
          <Link to="/investor_preferences">
            <img src={factorImportance} />
            <div className="bannerCardHeader">Investor Preferences</div>
            <div className="bannerText">
              {`Monitoring flows alone is not sufficient to understand investor preferences. Use Flowspring's factor model to 
                            disentangle investors' true preferences and study how they evolve.`}
            </div>
          </Link>
        </div>
      </div>
      <div className="buttonContainer">
        <a href="https://app.flowspring.com/accounts/signup">
          <div className="button">FREE TRIAL</div>
        </a>
      </div>
    </div>
    <ResearchBanner />
    <div className="newBanner">
      <div className="bannerHeader">Trusted by Experts</div>
      <div className="bannerSubHeader">You&apos;ll be in Good Company</div>
      <div>
        <div id="statsContainer">
          <div className="statContainer">
            <div className="stat">
              <CountUp
                end={4.3}
                duration={3}
                prefix=""
                suffix="x"
                decimals="1"
                decimal="."
                useEasing
              />
              {" "}
            </div>
            Ratio of Client Assets to Category Peers
          </div>
          <div className="statContainer">
            <div className="stat">
              <CountUp
                end={3.5}
                duration={3}
                prefix="$"
                suffix=" Tn"
                decimals="1"
                decimal="."
                useEasing
              />
              {" "}
            </div>
            Total Client Assets
          </div>
          <div className="statContainer">
            <div className="stat">
              <CountUp
                end={93}
                duration={3}
                prefix=""
                suffix=""
                decimals="0"
                decimal="."
                useEasing
              />
              {" "}
            </div>
            Investment products launched by clients over the last 10 years
          </div>
        </div>

        <div id="pressContainer">
          <img src={nyt} style={{ width: 270 }} />
          <img src={ft} style={{ width: 50 }} />
          <img src={ignites} style={{ width: 230 }} />
          <img src={citywire} style={{ width: 200 }} />
        </div>
      </div>
    </div>
  </>
);

export default Home;
